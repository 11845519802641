import { Masonry } from "@mui/lab";
import { Card, CardContent, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Route, Routes } from "react-router-dom";
import { NoUnderlineLink } from "../components/NoUnderlineLink";

import postlist from "../posts.json";

export interface Frontliner {
    title: string;
    subtitle: string;
    date: string;
    author: string;
    content?: string;
    preview?: React.Component;
}


export const BlogComponent = () => {
    return (<Blog />);
};

const StyledCard = (props: { item: Frontliner }) => {
    const { item } = props;

    const [elevation, setElevation] = useState(1);
    return (
        <Card
            onMouseOver={() => setElevation(5)}
            onMouseOut={() => setElevation(1)}
            elevation={elevation}
        >
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {item.date}
                </Typography>
                <Typography variant="h5" component="div">
                    {item.title}
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {item.subtitle}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {item.author}
                </Typography>
            </CardContent>
        </Card>
    );
};

export const Blog = () => {
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <>
            <Routes>
                <Route path="/" element={
                    <Masonry columns={matches ? 3 : 1}>
                        {postlist.map((item, i) =>
                            <NoUnderlineLink to={`/blog/${item.id}`} >
                                <StyledCard item={item}/>
                            </NoUnderlineLink>
                        )}
                    </Masonry>
                }/>

                    {postlist.map((item, i) => {
                        return (
                            <Route path={`/${item.id}`} element={(() => { return BlogPostComponent({ item }) })()} />
                        )
                    })}
            </Routes>
        </>
    );
};

export const BlogPostComponent = (props: { item: Frontliner, [extraAttributes: string]: unknown }) => {
    return (<BlogPost item={props.item} />);
};

export const BlogPost = (props: { item: Frontliner, [extraAttributes: string]: unknown }) => {
    const { item, ...other } = props;
    console.log(other);
    return (
        props.item.content
            ? <ReactMarkdown>{props.item.content}</ReactMarkdown>
            : null
    );
};