import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const MARGIN_TOP = "10px";

export const HomeComponent = () => {
    return(
        <Box 
            sx={{
                width:{xs: "100%", md: "50%"},
                textAlign: "center",
                margin: "auto"
            }}>
            <img src={require('../images/Logo.png')} alt="Logo"/>
            <Typography variant="h3">
                Hi!  I'm Colleen.
            </Typography>
            <Typography variant="body1" mt={MARGIN_TOP}>
                I'm a creator at heart, I love starting with nothing and turning it into something useful or beautiful.  
            </Typography>
            <Typography variant="body1" mt={MARGIN_TOP}>
                Professionally, I am a software engineer turned application designer.  I like creating applications that get the 
                job done as efficiently as possible, but are also fun and satisfying to use.  The user is my number one priority; 
                I want to understand the why behind a task and streamline a workflow into a beautiful and enjoyable experience.
            </Typography>
            <Typography variant="body1" mt={MARGIN_TOP}>
                My creationist passion isn't limited to my professional life.  You'll find lots of random projects here: fiber arts, 
                keyboard construction, my musical pursuits (banjo, flute, and recently violin) and a few thrift store restorations. 
            </Typography>
            <Typography mt={MARGIN_TOP}>
                I'm also an avid Auburn University fan (War Eagle!) and jet around between track, wrestling, dance, cross country, cello and double reed lessons.
            </Typography>
        </Box>
    );
}