import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import './index.css';
import "typeface-roboto"
import { HashRouter, Route, Routes } from "react-router-dom"


import reportWebVitals from './reportWebVitals';
import { menuItems } from './models/menuItems';
import { HeaderMenuItem } from './components/HeaderMenu';

import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  palette: {
    primary: {
      main: "#20237B",
    },
    secondary: {
      main: "#69306D",
    },
    background: {
        default: "#EEF0F2",
    }
  },
  typography: {
      h1: {
          fontSize: "3rem",
      }
  },
});

const getRoutes = () => {
    const routeList: Record<string, ()=> JSX.Element | undefined> = {};
    const getNoChildrenItems = (menuItem: HeaderMenuItem, routes: Record<string, unknown>) => {

        if (!menuItem.children) {
            routes[menuItem.id ? `/${menuItem.id}/*` : ""] = menuItem.content;
            return;
        }

        for (const child of menuItem.children)
            getNoChildrenItems(child, routes);
    };

    for (const item of menuItems)
        getNoChildrenItems(item, routeList);

    return routeList;
}

const routes = getRoutes();

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline>
                <HashRouter>
                    <Routes>
                        <Route path="/" element={<App />}>
                            {Object.keys(routes).map(route => {
                                return <Route path={`${route}`} element={routes[route] ? routes[route]() : null} />
                            })}
                            {/* <Navigate to="/home"/> */}
                        </Route>
                    </Routes>
                </HashRouter>
            </CssBaseline>
        </ThemeProvider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
