import { IconButton, Typography } from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { GitHub } from "@mui/icons-material";

export const ContactComponent = () => {
    return (
        <>
        <Typography variant="h1">
            Get in Touch!
        </Typography>
        <IconButton aria-label="linked in" href="https://www.linkedin.com/in/colleen-e-dixon">
            <LinkedInIcon />
        </IconButton>
        <IconButton aria-label="twitter" href="https://www.twitter.com/auburn2001grad">
            <TwitterIcon />
        </IconButton>
        <IconButton aria-label="github" href="https://github.com/colleendixon/projects">
            <GitHub/>
        </IconButton>
        </>

    );
}