import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer"
import { BaseSyntheticEvent, forwardRef, useMemo, useState } from "react";
import { menuItems } from "../models/menuItems";

import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
  } from 'react-router-dom';
import { HeaderMenuItem } from "./HeaderMenu";

interface ListItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    to: string;
    sx?: React.CSSProperties; // sx={props.indent ? { pl: 4 } : {}}
  }

const ListItemLink = (props: ListItemLinkProps) => {
    const { icon, primary, to } = props;
  
    const renderLink = useMemo(
      () =>
        forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(
          itemProps,
          ref,
        ) {
          return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
        }),
      [to],
    );
  
    return (
      <li>
        <ListItem button component={renderLink}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={primary} />
        </ListItem>
      </li>
    );
  }

export interface NavDrawerProps {
    open: boolean,
    toggleDrawer: (open: boolean) => void;
}

export interface NestedListProps {
    headerItem: HeaderMenuItem;
    indent: boolean;
}

export const NestedList = (props: NestedListProps) => {
    const [open, setOpen] = useState(false);

    const handleClick = (event: BaseSyntheticEvent) => {
        setOpen(!open);
        event.stopPropagation();
    }

    return (
        <>
            {props.headerItem.children ?
                <>
                    <ListItemButton onClick={handleClick} sx={props.indent ? { pl: 4 } : {}}>
                        <ListItemText primary={props.headerItem.name} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {props.headerItem.children.map(child => <NestedList headerItem={child} indent={true} />)}
                        </List>
                    </Collapse>
                </>
                : <ListItemLink to={props.headerItem.id ? `${props.headerItem.id}`: ""} primary={props.headerItem.name}/>
            }
        </>
    );
}

export const NavDrawer = (props: NavDrawerProps) => {

    return (
        <Drawer
            anchor="left"
            open={props.open}
            onClose={() => props.toggleDrawer(false)}
        >
            <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={() => props.toggleDrawer(false)}
                onKeyDown={() => props.toggleDrawer(false)}
            >
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {menuItems.map(item => <NestedList headerItem={item} indent={false} />)}
                </List>
            </Box>
        </Drawer>
    );
};