import "./App.css";
import {
    Box,
    Grid,
    IconButton,
    styled,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from "react";
import { NavDrawer } from "./components/NavDrawer";

const ContentGrid = styled(Grid)`
    padding: 16px;
`;

export const App = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    console.log(matches);

    const [open, setOpen] = useState(false);

    const toggleDrawer = (open: boolean) => {
        setOpen(open);
    };

    return (
        <Box sx={{ flexGrow: "1" }}>
            <Grid direction={"column"} container rowSpacing={0}>
                <Grid item>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => toggleDrawer(true)}
                        edge="start"
                        sx={{ margin: "8px", mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <NavDrawer
                        open={open}
                        toggleDrawer={toggleDrawer}/>
                </Grid>
                {/* <Grid item sx={{ paddingTop: "100px", width: "100%" }}>
                    <NavBar />
                </Grid> */}                
                <ContentGrid item>
                    <Outlet />
                </ContentGrid>
            </Grid>
        </Box>
    );
};
