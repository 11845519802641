import { HeaderMenuItem } from "../components/HeaderMenu";
import { BlogComponent, BlogPostComponent, Frontliner } from "../pages/Blog";
import { ContactComponent } from "../pages/Contact";
import { HomeComponent } from "../pages/Home";

import postlist from "../posts.json";

// TODO: Use the file name as the id
// Make sure each of these links has a route
// Change the id to the file name

export const menuItems: HeaderMenuItem[] = [
    {
        id: "",
        name: "Home",
        content: () => {
            return HomeComponent()
        }
    },
    {
        id: "blog",
        name: "Blog",
        content: () => {
            return BlogComponent()
        }
    },
    {
        id: "diy_mechanical_keyboard",
        name: "DIY Mechanical Keyboard",
        children: [
            {
                id: "keyboard_beginner",
                name: "Beginner",
                children: [
                    {
                        name: "Getting Started",
                        id: "getting_started",
                        content: () => {
                            const gettingStarted = postlist?.find(pl => pl.id === "2021-12-21-keyboard-info.md")
                            return BlogPostComponent({ item: gettingStarted as Frontliner })
                        }
                    },
                    {
                        name: "First Shipment",
                        id: "first_shipment",
                        content: () => {
                            const gettingStarted = postlist?.find(pl => pl.id === "2021-12-27-keyboard-parts.md")
                            return BlogPostComponent({ item: gettingStarted as Frontliner })                        }
                    },
                    {
                        name: "Assembly",
                        id: "assembly",
                        content: () => {
                            const gettingStarted = postlist?.find(pl => pl.id === "2022-01-02-keyboard-assembly.md")
                            return BlogPostComponent({ item: gettingStarted as Frontliner })                        }
                    },
                    {
                        name: "Finishing Up",
                        id: "finishing_up",
                        content: () => {
                            const gettingStarted = postlist?.find(pl => pl.id === "2022-01-10-finishing-up.md")
                            return BlogPostComponent({ item: gettingStarted as Frontliner })                        }
                    },
                    {
                        name: "Troubleshooting",
                        id: "troubleshooting",
                        content: () => {
                            const gettingStarted = postlist?.find(pl => pl.id === "2022-01-11-troubleshooting.md")
                            return BlogPostComponent({ item: gettingStarted as Frontliner })                        }
                    }
                ]
            },
            {
                id: "keyboard_intermediate",
                name: "Intermediate",
                children: [
                    {
                        name: "Ordering PCBs",
                        id: "ordering_pcbs",
                        content: () => {
                            const gettingStarted = postlist?.find(pl => pl.id === "2022-02-18-ordering-pcbs.md")
                            return BlogPostComponent({ item: gettingStarted as Frontliner })                        }
                    }
                ]
            },
        ]
    },
    {
        id: "3d_printing",
        name: "3D Printing"
    },
    {
        id: "contact",
        name: "Contact",
        content: () => {
            return ContactComponent()
        }
    },
];

